@media screen and (max-width: 500px) {
    .context {
        top: 7% !important;
        width: 370px !important;
        height: 85% !important;
        left: 5% !important;
        display: grid !important;
        z-index: inherit !important;

}
.context > div {
    width: 100% !important;
}
}

.scroller .context {
    left: 19%;
    top: 16%;
}


.context {
    width:600px;
     margin: auto;
    /* backdrop-filter: blur(10px); */
    box-shadow: 20px 20px 40px -6px rgba(0,0,0,0.2);
    border: 2px solid rgba(255,255,255,0.5);
    z-index: 99;
    height: 400px;
    position: absolute;
    top: 20%;
    left: 28%;
    display: flex;
    padding: 5px;
    border-radius: 5px;
    
}
.context > div{
    width: 50%;
}
.context .left{
   backdrop-filter: blur(5px);

padding-top: 65px;
padding-left: 30px;


}
.context .right{
   background: #FFF ;

padding-top: 30px;
}
.paragraphtext{
 color: #ffffff;
    }
.context .helpForm{
    padding: 30px;
}
.context input, textarea{
 margin-bottom:20px;
 background: transparent;
 width: 100%;
 color: #111;
 border-top :2px solid transparent;
 border-left :2px solid transparent;
 border-right :2px solid transparent;
 /* border-bottom :2px solid #111; */
}

.context textarea:focus, input:focus{
    outline: none;
}

.context .left h1{
    text-align: left;
   color: #ffffff;
text-shadow: -1px 1px #3f3c3c;
   font-border: 1px solid rgba(255, 255, 255, 0.65);
    font-size: 26px;
    backdrop-filter: blur(10px);
}

.helpSubmitBtn{
    box-shadow: 20px 20px 40px -6px rgba(0,0,0,0.2);
    border: 2px solid rgba(255,255,255,0.5);
    width: 100%;
    height: 40px;
    border-radius: 23px;
    margin-top:20px;
}
.textare_resize {
    resize: none !important;
  }
.contact_help_text{
    margin-top: 20px !important;
}
.area{
  /* background-color: rgb(160, 157, 157); */
/* background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%); */

  
    width: 100%;
    /* height:100vh; */
    
   
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.2);
  
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 80px;
  border: 1px solid rgba(255, 255, 255, 0.18);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

@media screen and (max-width: 500px) {

     .context {
    
   top: 7% !important;
    
 /* width: 100% !important; */
    
    /* height: 100% !important; */
    
    left: 5% !important;
    
   display: grid !important;
    
   /* z-index: 1 !important; */
    
 
    }
    
    .context > div {
    
 width: 100% !important;
    
    }
    
    }

    .scroller .context {
        left: 19%;
        top: 16%;
        
        }